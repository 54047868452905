import { Transition } from "@headlessui/react";
import classNames from "classnames";

function ToastTransition({ children, className, ...props }) {
  return (
    <Transition
      appear
      className={classNames(
        "translate-y-0 opacity-100 transition duration-200",
        "data-[enter]:data-[closed]:translate-y-full data-[leave]:data-[closed]:opacity-0",
        className
      )}
      {...props}
    >
      {children}
    </Transition>
  );
}

export default ToastTransition;
